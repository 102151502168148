<template>
  <v-card>
    <v-card-title>在庫登録</v-card-title>
    <v-form ref="form">
      <v-card-title>発送情報</v-card-title>
      <v-row>
        <ctk-date-time-picker v-model="receiveDatetime" :format="dateFormat" :overlay="true" label="受取日時" :rules="rules.notNull">
          <template v-slot:append-outer>
            <v-text-field v-model="receiveDatetime" single-line label="受取日時">
            </v-text-field>
          </template>
        </ctk-date-time-picker>
      </v-row>
      <v-row>
        <v-col>
          <v-select dense outlined v-model="receiveUserId" item-text="userName" item-value="userId" :items="shippers" label="受取ユーザ名" />
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-text-field dense outlined v-model="orderCd" label="注文コード" />
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-text-field dense outlined v-model="shippingCompanyName" label="発送業者名" />
        </v-col>
        <v-col>
          <v-text-field dense outlined v-model="traceUrl" label="追跡URL" />
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-text-field dense outlined v-model.number="actualWeight" label="実際重量" />
        </v-col>
        <v-col>
          <v-text-field dense outlined v-model.number="volumeWeight" label="容積重量" />
        </v-col>
        <v-col>
          <v-text-field dense outlined readonly v-model.number="settlementWeight" label="決済重量" />
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-text-field dense outlined v-model.number="purchaseCurrencyShippingCost" label="仕入通貨送料" />
        </v-col>
        <v-col>
          <v-text-field dense outlined v-model.number="exchangeRate" label="通貨レート" />
        </v-col>
        <v-col>
          <v-text-field dense outlined v-model="purchaseCurrency" label="仕入通貨" />
        </v-col>
        <v-col>
          <v-text-field dense outlined readonly v-model.number="shippingCost" label="送料（円）" />
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-text-field dense outlined v-model.number="importCustomsAmount" label="輸入関税額" />
        </v-col>
        <v-col>
          <v-text-field dense outlined v-model.number="importConsumptionTaxAmount" label="輸入消費税額" />
        </v-col>
        <v-col>
          <v-text-field dense outlined v-model.number="importLocalConsumptionTaxAmount" label="輸入地方消費税額" />
        </v-col>
        <v-col>
          <v-text-field dense outlined v-model.number="advanceCommission" label="立替手数料" />
        </v-col>
        <v-col>
          <v-text-field dense outlined readonly v-model.number="taxSum" label="税金合計" />
        </v-col>
      </v-row>
      <v-divider/>
      <v-card-title>仕入在庫情報</v-card-title>
      <v-row>
        <v-col>
          <v-btn @click="addItem">行追加</v-btn>
        </v-col>
      </v-row>
      <v-data-table :headers=headers :items=items :options="options">
        <template v-slot:item.itemTopImagePath="{ item }">
          <v-img :src="getItemTopImagePath(item)" max-height="80" max-width="80" />
        </template>
        <template v-slot:item.uniqueItemName="{ item }">
          <v-autocomplete outlined dense label="商品名*" :items="itemStocksCache" item-text="uniqueItemName" item-value="itemId" v-model.trim="item.itemId" :search-input.sync="item.uniqueItemName" :rules="rules.notNull" />
        </template>
        <template v-slot:item.quantity="{ item }">
          <v-text-field dense v-model.number="item.quantity" single-line label="" />
        </template>
        <template v-slot:item.purchaseCurrencyPrice="{ item }">
          <v-text-field dense v-model.number="item.purchaseCurrencyPrice" single-line label="" />
        </template>
        <template v-slot:item.exchangeRate="{ item }">
          <v-text-field dense v-model.number="item.exchangeRate" single-line label="" />
        </template>
        <template v-slot:item.purchaseCurrencyBuyingCommission="{ item }">
          <v-text-field dense v-model.number="item.purchaseCurrencyBuyingCommission" single-line label="" />
        </template>
        <template v-slot:item.purchaseCurrencyInspectionFee="{ item }">
          <v-text-field dense v-model.number="item.purchaseCurrencyInspectionFee" single-line label="" />
        </template>
        <!-- <template></template> -->
      </v-data-table>
      <v-row>
        <v-col>
          <v-btn @click="submit">在庫登録</v-btn>
        </v-col>
      </v-row>
    </v-form>
    <v-snackbar color="success" v-model="success" timeout=10000 right multi-line block>登録成功!
      <template v-slot:action="{ attrs }">
        <v-btn
          dark
          text
          v-bind="attrs"
          @click="success = false"
        >
          ×
        </v-btn>
      </template>
    </v-snackbar>
    <v-snackbar color="error" timeout=10000 v-model="hasError" right multi-line block>{{errMessage}}
      <template v-slot:action="{ attrs }">
        <v-btn
          dark
          text
          v-bind="attrs"
          @click="hasError = false"
        >
          ×
        </v-btn>
      </template>
    </v-snackbar>
  </v-card>
</template>

<script>
  //import DateTimePicker from "./../components/DateTimePicker";
  import axios from 'axios'
  import { mapGetters } from 'vuex'
  import CtkDateTimePicker from 'vue-ctk-date-time-picker'
  import 'vue-ctk-date-time-picker/dist/vue-ctk-date-time-picker.css';
  import ValidationRules from '../consts/validation_rules.js'
  import firebaseStorageUtil from '../utils/firebase_storage_util'
  import Endpoints from '../consts/api_endpoint_map.js'

  export default {
    name: "RegisterSales",
    components: {
      // DateTimePicker
      CtkDateTimePicker,
    },
    data () {
      return {
        dateFormat: "YYYY-MM-DD HH:mm:ss",
        headers: [
          {text: "商品画像", value: "itemTopImagePath"},
          {text: "ユニーク商品名", value: "uniqueItemName"},
          {text: "数量", value: "quantity"},
          // {text: "在庫数", value: "stock_quantity"},
          {text: "仕入通貨価格（一品当たり）", value: "purchaseCurrencyPrice"},
          {text: "通貨レート", value: "exchangeRate"},
          {text: "仕入通貨買付手数料（一品）", value: "purchaseCurrencyBuyingCommission"},
          {text: "仕入通貨検品手数料（一品）", value: "purchaseCurrencyInspectionFee"},
        ],
        // header data
        orderCd: "",
        receiveDatetime: "",
        receiveUserId: "",
        shippingCompanyName: "ラクマート",
        traceUrl: "",
        actualWeight: 0,
        volumeWeight: 0,
        purchaseCurrencyShippingCost: 0,
        purchaseCurrency: "CNY",
        exchangeRate: 1,
        importCustomsAmount: 0,
        importConsumptionTaxAmount: 0,
        importLocalConsumptionTaxAmount: 0,
        advanceCommission: 0,
        taxRefund: 0,
        // item list data
        items: [
        ],
        options: {
          itemsPerPage: 30
        },
        itemStocksCache: [],
        itemStocksMapCache: {},
        shippers: [],
        rules: ValidationRules,
        // snackbar
        success: false,
        hasError: false,
        errMessage: "",
      }
    },
    computed: {
      settlementWeight() {
        return Math.max(this.actualWeight, this.volumeWeight)
      },
      shippingCost() {
        return this.purchaseCurrencyShippingCost * this.exchangeRate
      },
      taxSum() {
        return this.importCustomsAmount
         + this.importConsumptionTaxAmount
         + this.importLocalConsumptionTaxAmount
         + this.advanceCommission
      },
      ...mapGetters([
        'userId'
      ])
    },
    watch: {
    },
    async created () {
      await axios.get(Endpoints.getCacheItemStocksEndpoint)
        .then(res => {
          this.itemStocksCache = res.data
        })
        .catch(err => {
          console.log(err)
        })
      await axios.get(Endpoints.getCacheItemStocksMapEndpoint)
        .then(res => {
          this.itemStocksMapCache = res.data
        })
        .catch(err => {
          console.log(err)
        })
      // get receiveUserId list and set myself for receive user by default
      await axios.get(Endpoints.getShippersEndpoint)
        .then(res => {
          this.shippers = res.data
        })
        .catch(err => {
          console.log(err)
        })
      this.receiveUserId = this.userId
      // image download
      var item
      for(item of this.itemStocksCache){
        item.itemTopImagePath = await firebaseStorageUtil.getImgUrl(item.itemTopImagePath) 
      }
      for(var key in this.itemStocksMapCache){
        item = this.itemStocksMapCache[key]
        item.itemTopImagePath = await firebaseStorageUtil.getImgUrl(item.itemTopImagePath) 
      }
      console.log("getImageUrl done")
      console.log(this.itemStocksCache)
      console.log(this.itemStocksMapCache)
    },
    methods: {
      addItem() {
        this.items.push({})
      },
      getItemTopImagePath(item){
        if(!item.itemId){
          return ""
        }
        const itemStock = this.itemStocksMapCache[item.itemId]
        if(!itemStock){
          return ""
        }
        // firebase picUrl
        return itemStock.itemTopImagePath
      },
      submit() {
        if(!this.$refs.form.validate()){
          return
        }
        // validate ctk-date-time-picker values
        if(!this.receiveDatetime){
          this.success = false
          this.hasError = true
          this.errMessage = "日付フィールドを入力してください"
          return 
        }
        // go submit!
        this.isLoading = true
        this.$emit("start-loading")
        // arrange request data
        const tmpItems = this.items.filter(i => i.itemId);
        const itemCount = tmpItems.reduce((sum, item) => sum + item.quantity, 0)
        console.log(itemCount)
        const req = {
          purchasingUnitStock: {
            orderCd: this.orderCd,
            receiveDatetime: this.receiveDatetime,
            shippingCompanyName: this.shippingCompanyName,
            traceUrl: this.traceUrl,
            actualWeight: this.actualWeight,
            volumeWeight: this.volumeWeight,
            settlementWeight: this.settlementWeight,
            purchaseCurrencyShippingCost: this.purchaseCurrencyShippingCost,
            purchaseCurrency: this.purchaseCurrency,
            exchangeRate: this.exchangeRate,
            shippingCost: this.shippingCost,
            importCustomsAmount: this.importCustomsAmount,
            importConsumptionTaxAmount: this.importConsumptionTaxAmount,
            importLocalConsumptionTaxAmount: this.importLocalConsumptionTaxAmount,
            advanceCommission: this.advanceCommission,
            taxSum: this.taxSum,
            taxRefund: this.taxRefund,
          },
          stockList: tmpItems
            .map(i => ({
              receiveDatetime: this.receiveDatetime,
              receiveUserId: this.receiveUserId,
              itemId: i.itemId,
              uniqueItemName: i.uniqueItemName,
              quantity: i.quantity,
              stockQuantity: i.quantity, // eq quantity
              purchaseCurrencyPrice: i.purchaseCurrencyPrice,
              exchangeRate: i.exchangeRate,
              price: i.purchaseCurrencyPrice * i.exchangeRate,
              purchaseCurrencyBuyingCommission: i.purchaseCurrencyBuyingCommission,
              buyingCommission: i.purchaseCurrencyBuyingCommission * i.exchangeRate,
              purchaseCurrencyInspectionFee: i.purchaseCurrencyInspectionFee,
              inspectionFee: i.purchaseCurrencyInspectionFee * i.exchangeRate,
              shippingCostPerItem: this.shippingCost * 1.0 / itemCount,
              taxAmountPerItem: this.taxSum * 1.0 / itemCount,
            }))
            .map(i => ({
              ...i,
              itemCost: i.price + i.buyingCommission + i.shippingCostPerItem + i.taxAmountPerItem
            }))
        }
        console.log(`request : `)
        console.log(req)
        axios.post(Endpoints.registerStockEndpoint, req)
          .then(res => {
            console.log("received response from stocks register")
            console.log(res)
            if(!res.data.hasError){
              this.success = true
              this.hasError = false
              this.errMessage = ""
              // フォームをreset
              // this.init()
            } else {
              this.success = false
              this.hasError = true
              this.errMessage = res.data.errCode + "\n" + res.data.errMessage
            }
            this.isLoading = false
            this.$emit("end-loading")
          })
          .catch(err => {
            console.log("err:", err)
            this.success = false
            this.hasError = true
            this.errMessage = err
            this.isLoading = false
            this.$emit("end-loading")
          })
      }
    }
  }
</script>

<style>
</style>
